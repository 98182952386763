// Get all projects with category, limit, and offset
// Get all projects with category, limit, and offset
export function getProjects(categoryCriteria, limit, offset) {
    return `query fetchProjects {
    entriesConnection( section: [projects], limit: ${limit}, offset: ${offset}, relatedTo: [ ${categoryCriteria} ] ) {
        totalCount
        pageInfo {
            currentPage
            totalPages
        }
    }
    entries( section: [projects], limit: ${limit}, offset: ${offset}, relatedTo: [ ${categoryCriteria} ] ) {
      ... on ProjectsProject {
        title
        id
        projectLocation
        url
        completionDate
        postDate
        officeLocation {
          title
        }
        slideshow {
          ... on SlideshowSlide {
            image {
              ... on HerosVolume {
                title
                optimizedGridImage { 
                  src
                  srcset
                  srcWebp
                  srcsetWebp
                  maxSrcsetWidth
                  placeholderImage
                  placeholderBox
                  placeholderSilhouette
                }
              }
            }
          }
        }
      }
    }
  }`;
}

// Get all projects title, id, url with no limit for filtered results prev/next links
export function getProjectOrder(categoryCriteria) {
    return `query fetchProjects {
    entries( section: [projects], relatedTo: [ ${categoryCriteria} ] ) {
      ... on ProjectsProject {
        title
        id
        url
      }
    }
  }`;
}

// Get all catalysts with category, limit, and offset
export function getCatalysts(categoryCriteria, limit, offset) {
    return ` query fetchCatalysts {
        entryCount(section: "catalyst", relatedToCategories: { ${categoryCriteria} } )
        entries( section: "catalyst", limit: ${limit}, offset: ${offset}, relatedToCategories: { ${categoryCriteria} } ) {
            ...on catalyst_catalyst_Entry {
                title,
                url,
                id,
                metaDescription,
                postDate @formatDateTime (format: "m.j.Y"),
                typeHandle
                heroImage {
                ...on heros_Asset {
                  title,
                  optimizedListImage {
                      src,
                      srcset,
                      srcWebp,
                      srcsetWebp,
                      maxSrcsetWidth,
                      placeholderImage,
                      placeholderBox,
                      placeholderSilhouette
                  }
                }
              }
            }
        }
    }`;
}

// Get all videos with category, limit, and offset
export function getVideos(categoryCriteria, limit, offset) {
    return `query fetchVideos {
       entryCount(section: "videos", relatedToCategories: { ${categoryCriteria} } )
       entries(section: "videos", limit: ${limit}, offset: ${offset}, relatedToCategories: { ${categoryCriteria} } ) {
           ...on videos_videos_Entry {
            title,
            url,
            metaDescription,
            postDate @formatDateTime (format: "m.j.Y"),
              typeHandle
               posterImage {
                ...on heros_Asset {
                  title,
                  optimizedListImage {
                      src,
                      srcset,
                      srcWebp,
                      srcsetWebp,
                      maxSrcsetWidth,
                      placeholderImage,
                      placeholderBox,
                      placeholderSilhouette
                  }
                }
              }
          }
       }
   }`;
}

// Get all releases with category, limit, and offset
export function getReleases(categoryCriteria, limit, offset) {
    return ` query fetchReleases {
      entryCount(section: "releases", relatedToCategories: { ${categoryCriteria} } )
      entries(section: "releases", limit: ${limit}, offset: ${offset}, relatedToCategories: { ${categoryCriteria} } ) {
          ...on releases_releases_Entry {
              title
              url
              metaDescription
              postDate @formatDateTime (format: "m.j.Y")
              typeHandle
               heroImage {
                    ...on heros_Asset {
                        title
                          optimizedListImage {
                            src
                            srcset
                            srcWebp
                            srcsetWebp
                            maxSrcsetWidth
                            placeholderImage
                            placeholderBox
                            placeholderSilhouette
                      }
                    }
                  }
          }
       }
   }`;
}

// Get all research with category, limit, and offset
export function getResearch(categoryCriteria, limit, offset) {
    return ` query fetchResearch {
      entryCount(section: "research", relatedToCategories: { ${categoryCriteria}} )
      entries(section: "research", limit: ${limit}, offset: ${offset}, relatedToCategories: {${categoryCriteria}} ) {
          ...on research_research_Entry {
            title,
            url,
            metaDescription,
            postDate @formatDateTime (format:"m.j.Y"),
              typeHandle
              heroImage {
                    ...on heros_Asset {
                      title,
                      optimizedListImage {
                          src,
                          srcset,
                          srcWebp,
                          srcsetWebp,
                          maxSrcsetWidth,
                          placeholderImage,
                          placeholderBox,
                          placeholderSilhouette
                      }
                    }
                  }
                  
          }
       }
   }`;
}

// Get all news with category, limit, and offset
export function getNews(categoryCriteria, limit, offset) {
    return `query fetchNews {
      entryCount(section: "news", relatedToCategories: { ${categoryCriteria} } )
       entries(section: "news", limit: ${limit}, offset: ${offset}, relatedToCategories: { ${categoryCriteria} } ) {
           ...on news_news_Entry {
            title,
            url,
            metaDescription,
            postDate @formatDateTime (format:"m.j.Y"),
              typeHandle
               heroImage {
                    ...on heros_Asset {
                      title,
                      optimizedListImage {
                          src,
                          srcset,
                          srcWebp,
                          srcsetWebp,
                          maxSrcsetWidth,
                          placeholderImage,
                          placeholderBox,
                          placeholderSilhouette
                      }
                    }
                  }
          }
       }
   }`;
}

// Get all insights with category, limit, and offset
export function getInsights(categoryCriteria, limit, offset) {
    return ` query fetchInsights {
  entryCount(section: ["releases", "news", "videos", "research", "catalyst"])
  entries(
    section: ["releases", "news", "videos", "research", "catalyst"]
    orderBy: "postDate desc"
    limit: ${limit}
    offset: ${offset}
    relatedToCategories: { id: ${categoryCriteria} }
  ) {
    ... on releases_releases_Entry {
      title
      url
      id
      metaDescription
      postDate
      typeHandle
      heroImage {
        ... on heros_Asset {
          title
          optimizedListImage {
            src
            srcset
            srcWebp
            srcsetWebp
            maxSrcsetWidth
            placeholderImage
            placeholderBox
            placeholderSilhouette
          }
        }
      }
    }
    ... on research_research_Entry {
      title
      url
      id
      metaDescription
      postDate
      typeHandle
      heroImage {
        ... on heros_Asset {
          title
          optimizedListImage {
            src
            srcset
            srcWebp
            srcsetWebp
            maxSrcsetWidth
            placeholderImage
            placeholderBox
            placeholderSilhouette
          }
        }
      }
    }
    ... on news_news_Entry {
      title
      url
      id
      metaDescription
      postDate
      typeHandle
      heroImage {
        ... on heros_Asset {
          title
          optimizedListImage {
            src
            srcset
            srcWebp
            srcsetWebp
            maxSrcsetWidth
            placeholderImage
            placeholderBox
            placeholderSilhouette
          }
        }
      }
    }
    ... on catalyst_catalyst_Entry {
      title
      url
      id
      metaDescription
      postDate
      typeHandle
      heroImage {
        ... on heros_Asset {
          title
          optimizedListImage {
            src
            srcset
            srcWebp
            srcsetWebp
            maxSrcsetWidth
            placeholderImage
            placeholderBox
            placeholderSilhouette
          }
        }
      }
    }
    ... on videos_videos_Entry {
      title
      url
      id
      metaDescription
      postDate
      typeHandle
      posterImage {
        ... on heros_Asset {
          title
          optimizedListImage {
            src
            srcset
            srcWebp
            srcsetWebp
            maxSrcsetWidth
            placeholderImage
            placeholderBox
            placeholderSilhouette
          }
        }
      }
    }
  }
}`;
}

// Get all playlists with category, limit, and offset
export function getPlaylists(categoryCriteria) {
    return `query fetchPlaylists {
    entries(section: "playlists",  relatedToCategories: {id: ${categoryCriteria} }, orderBy: "postDate desc") {
    ... on playlists_playlist_Entry {
      title
      postDate
      url
      projectSlideshow {
        ... on projectSlideshow_slide_BlockType {
          image {
            ... on heros_Asset {
              title
              optimizedGridImage {
                src
                srcset
                srcWebp
                srcsetWebp 
                maxSrcsetWidth
                placeholderImage
                placeholderBox
                placeholderSilhouette
              }
            }
          } 
        }
      }
    }
   }
  } 
  `;
}

// Get all team members
export function getTeamMembers(filterParams, regionParams, limit, offset) {
    return `query fetchTeamMembers {
    entryCount(
            section: "teamMembers"
            limit: ${limit}
            offset: ${offset}
            orderBy: "lastName asc"
            relatedToCategories: { id: ${filterParams} }
            relatedToEntries: { id: ${regionParams} }
    )
    entries(
            section: "teamMembers"
            limit: ${limit}
            offset: ${offset}
            orderBy: "lastName asc"
            relatedToCategories:  {id: ${filterParams} }
            relatedToEntries: { id: ${regionParams} }
        ) {
        ... on teamMembers_teamMembers_Entry {
              title
              url
              id
              position
              officeLocation {
                title
               ... on locations_location_Entry {
                  stateAbbreviation
                }
              }
              headshot {
                ... on headshots_Asset {
                  title
                  optimizedHeadshots {
                    src
                    srcset
                    srcWebp
                    srcsetWebp
                    maxSrcsetWidth
                    placeholderImage
                    placeholderBox
                    placeholderSilhouette
                  }
                }
              }
            }
        }
    }`;
}

export function getCategoryTeam(category) {
    return `query fetchServices {
  categories(groupId: 1, id: ${category}) {
    id
    title
    ... on ExpertiseCategory {
      team {
        id
        title
        url
        ... on TeamMembers {
          position
          officeLocation {
            title
            ... on LocationsLocation {
              stateAbbreviation
            }
          }
          headshot {
            ... on HeadshotsVolume {
              title
              optimizedHeadshots {
                src
                srcset
                srcWebp
                srcsetWebp
                maxSrcsetWidth
                placeholderImage
                placeholderBox
                placeholderSilhouette
              }
            }
          }
        }
      }
    }
  }
  }`;
}

// getProjectsLength
export function getProjectsLength(category) {
    return `query fetchServices {
  categories(groupId: 1, id: ${category}) {
    id
    ... on ExpertiseCategory {
      projects {
        id
      }
    }
  }
  }`;
}

export function getCategoryProjects(category, limit, offset) {
    return `query fetchServices {
  categories(groupId: 1, id: ${category}) {
    id
    title
    ... on ExpertiseCategory {
      projects(limit: ${limit}, offset: ${offset}) {
        id
        title
        url
        postDate
        ... on ProjectsProject {
          slideshow {
            ... on SlideshowSlide {
              image {
                ... on HerosVolume {
                  title
                  optimizedGridImage {
                    src
                    srcset
                    srcWebp
                    srcsetWebp
                    maxSrcsetWidth
                    placeholderImage
                    placeholderBox
                    placeholderSilhouette
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }`;
}

// Get all roles
export function getRoles() {
    return `query fetchRoles {
          categories(groupId: 5) {
            id
            title
        }
    }`;
}

// Get all regions (from section)
export function getRegions() {
    return `query fetchRegions {
     entries(section: "locations", orderBy: "title asc") {
     ... on locations_location_Entry {
            id
            title
     }
     }
  } 
  `;
}

// Get all regions (from categories)
export function getRegionCategories() {
    return `query fetchServices {
     categoriesConnection(groupId: 3, orderBy: "title asc") {
         edges {
              node {
                id
                title
              }
             relatedEntries(section: locations) {
                entries {
                  id
                }
             }
         }
     }
  } 
  `;
}

// Get all services
export function getServices() {
    return `query fetchServices {
     categories(groupId: 2, orderBy: "title asc") {
        id
        title
     }
  } 
  `;
}
// Get all expertise
export function getExpertise() {
    return `query fetchServices {
     categories(groupId: 1, orderBy: "title asc") {
        id
        title
     }
  } 
  `;
}

