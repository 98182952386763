<template>
    <div id="listingContainer">

        <transition-group>
            <insight-item
                    v-for="(entry, index) in entries"
                    :title="entry.title"
                    :url="entry.url"
                    :postDate="entry.postDate"
                    :metaDescription="entry.metaDescription"
                    :typename="entry.typeHandle"
                    :image="entry.posterImage ? entry.posterImage[0] : entry.heroImage[0]"
                    :key="entry.id"
            />
        </transition-group>

        <div v-if="currentPage < totalPages" style="width: 100%;">
            <a aria-label="Load more insights"
               v-on:click="getMoreInsights()"
               id="loadMore-1"
               class="load-more button red-outline-button"
            >
                <span>Load More</span>
                <div class="spinner spinner--insights">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    const apiToken = 'crg01qh0qBHY-uimtEyalTVakRr22YO2';
    // Import components
    import InsightItem from './InsightItem';

    // Import queries
    import {
        getInsights,
    } from '../Queries.js';

    export default {
        components: {
            InsightItem,
        },

        props: {
           categoryId: Number
        },

        mounted: function() {
            this.getInsights();
        },

        data() {
            return {
                entries: [],
                filterParams: [],
                expertiseCategory: this.categoryId,
                offset: 0,
                limit: 8,
                currentPage: 0,
                totalPages: 0,
                loadMoreButton: $('#loadMore')

            }
        },

        methods: {

            getInsights: function () {
                const data = {
                    query: getInsights(
                        this.expertiseCategory,
                        this.limit,
                        this.offset
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    const entries = response.data.entries;

                    this.currentPage = 1;
                    this.totalPages = Math.ceil(response.data.entryCount/this.limit);

                    this.entries = entries;


                    console.log(this.entries);
                    this.offset = this.offset + this.limit;
                });
            },

            getMoreInsights: function () {
                const data = {
                    query: getInsights(
                        this.expertiseCategory,
                        this.limit,
                        this.offset
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                    beforeSend: () => {
                        $('#loadMore-1').addClass('loading-more');
                    }
                };

                $.ajax(apiCallSettings).done(response => {
                    const entries = response.data.entries;

                    this.totalPages = Math.ceil(response.data.entryCount/this.limit);

                    this.entries = this.entries.concat(entries);

                    $('#loadMore-1').removeClass('loading-more');
                    this.offset = this.offset + this.limit;
                });
            },

        },


    };
</script>
<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
