<template>
    <div>
        <project-filter
            v-on:updateFilterParams="updateFilterParams"
            v-on:clearFilterParams="clearFilterParams"
        >
            <p class="sort-toggle">Sort By
                <span v-on:click="orderEntriesBy('date')">date</span>
                <span  v-on:click="orderEntriesBy('title')">A-Z</span>
            </p>
        </project-filter>

        <section id="projectGrid" class="project-grid full-width aos-item" style="min-height: 600px;" data-aos="fade">
            <p v-cloak v-if="!projects.length" class="no-results aos-item">No results found.</p>

            <transition-group>
                <project-grid-item
                    v-for="(project, index) in projects"
                    :title="project.title"
                    :location="project.projectLocation"
                    :url="project.url"
                    :image="project.slideshow[0].image[0]"
                    :id="project.id"
                    :key="project.id"
                />
            </transition-group>

            <div style="display: inline-block; width: 100%;">
                <scroll-loader :loader-method="getMoreProjects" :loader-enable="loadMore" v-if="loadMore === true">
                    <div aria-label="Loading more projects" class="loading-icon">
                        <div class="spinner spinner--project">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </scroll-loader>
            </div>
        </section>

    </div>
</template>

<script>
    import Vue from 'vue'
    // Import components
    import ScrollLoader from 'vue-scroll-loader'
    import ProjectFilter from './ProjectFilter';
    import ProjectGridItem from './ProjectGridItem';

    // Import queries
    import {
        getProjects,
        getProjectOrder,
    } from '../Queries.js';

    // Import local storage functions
    import {
        setCheckboxesFromLocalStorage,
        updateProjectsLocalStorage,
        clearLocalStorage
    } from '../LocalStorage.js';

    Vue.use(ScrollLoader);

    const apiUrl = window.location.href.split('/')[1] + "/api-dep";
    const apiToken = 'zTS0eWOCpo4-QKUfAQ9PGudQe20RWKqt7N0RLb40VN14KSXKLLLDoJ6YM1y-ojEJ';

    export default {
        components: {
            ProjectFilter,
            ProjectGridItem
        },

        mounted: function() {
            this.getProjects();
        },

        data() {
            return {
                projects: [],
                filterParams: [],

                offset: 0,
                limit: 9,
                currentPage: 0,
                totalPages: 0,
                loadMore: false,
                updateFilter: false,
                orderAsc: false

            }
        },

        methods: {
            getProjects: function () {
                const data = {
                    query: getProjects(
                        this.filterParams,
                        this.limit,
                        this.offset
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api-dep',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    const entries = response.data.entries;

                    this.currentPage = 1;
                    this.totalPages = Math.ceil(response.data.entryCount/this.limit);

                    this.projects = entries;

                    if ( this.updateFilter === true ) {
                        this.updateFilter = false;
                    }

                    this.loadMore = this.currentPage !== this.totalPages;
                    this.offset = this.offset + this.limit;

                });
            },

            getMoreProjects: function () {
                const data = {
                    query: getProjects(
                        this.filterParams,
                        this.limit,
                        this.offset
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api-dep',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {

                    const entries = response.data.entries;

                    this.projects = this.projects.concat(entries);

                    this.offset = this.offset + this.limit;
                    this.totalPages = Math.ceil(response.data.entryCount/this.limit);
                    this.currentPage = this.currentPage+1;

                    this.loadMore = this.currentPage !== this.totalPages;

                });
            },

            checkLocalStorage: function () {
                const criteria = setCheckboxesFromLocalStorage();
                const categoryCriteria = criteria.categoryCriteria;
                const regionCriteria = criteria.regionCriteria;

                if (categoryCriteria.length) {
                    this.selectedCategories = categoryCriteria;
                }

                if (regionCriteria.length) {
                    this.selectedRegions = criteria.regionCriteria;
                }

                this.updateProjectFilterParams();

            },

            getProjectsOrder: function () {
                const data = {
                    query: getProjectOrder(this.categoryCriteria)
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: this.apiUrl,
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    const entriesOrder = response.data.entries;

                    localStorage.projectOrderUrls = JSON.stringify(entriesOrder);

                });

            },

            updateFilterParams(value) {
                this.filterParams = value;
                this.offset = 0;
                this.updateFilter = true;
                this.loadMore = false;

                this.getProjects();
            },

            clearFilterParams() {
                this.offset = 0;
                this.filterParams = [];
                this.updateFilter = true;
                this.currentPage = true;
                this.totalPages = true;

                this.getProjects();
            },

            orderEntriesBy: function (orderBy) {
                this.orderAsc = this.orderAsc === false ? true : false;

                if (orderBy === 'title') {
                    this.sortByName(this.orderAsc);
                } else {
                    this.sortByDate(this.orderAsc);
                }

            },

            sortByName: function (orderAsc) {
                const sorted = this.projects.sort((a, b) => {
                    const titleA = a.title.toUpperCase();
                    const titleB = b.title.toUpperCase();

                    if (orderAsc === true) {

                        if (titleA < titleB) {
                            return -1;
                        }

                        if (titleA > titleB) {
                            return 1;
                        }

                    } else {
                        if (titleB < titleA) {
                            return -1;
                        }

                        if (titleB > titleA) {
                            return 1;
                        }

                    }
                    return 0;

                });

                this.projects = [
                    ...sorted
                ];

            },

            sortByDate: function (orderAsc) {
                const sorted = this.projects.sort((a, b) => {
                    if (orderAsc === true) {
                        return a.postDate - b.postDate;
                    } else {
                        return b.postDate - a.postDate;
                    }

                });

                this.projects = [
                    ...sorted
                ];
            },



        },

        updated: function () {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been re-rendered
                $('.no-results').css('display', 'block');
            });
        }

    };
</script>
<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>


