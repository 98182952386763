import { render, staticRenderFns } from "./ProjectExpertiseItem.vue?vue&type=template&id=6424025e"
import script from "./ProjectExpertiseItem.vue?vue&type=script&lang=js"
export * from "./ProjectExpertiseItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports