<template>
    <div>
        <div style="position: relative;" class="filter-toggle-wrapper aos-item" data-aos="fade">
            <p class="filter-toggle">Filters <span></span></p>
            <slot><!-- We are slotting in sort functions --></slot>
            <div class="clear"></div>
        </div>

        <div class="filter-menu" id="filter-menu">
            <div>
                <div id="sticky-anchor">
                    <div class="filter-menu-inner" style="opacity: 1;">

                        <h4>Expertise</h4>
                        <ul>
                            <li v-for="(category, index) in expertise">
                                <label class="control control--checkbox">
                                    <span class="filter-menu-item">{{ category.title }}</span>
                                    <input
                                        class="filter-checkbox"
                                        type="checkbox"
                                        :value="category.id"
                                        :key="category.id"
                                        v-model="selectedCategories"
                                        v-on:change="updateFilterParams($event);"
                                    />
                                    <div class="control__indicator"></div>
                                </label>
                            </li>
                        </ul>

                        <h4>Services</h4>
                        <ul>
                            <li v-for="(service, index) in services">
                                <label class="control control--checkbox">
                                    <span class="filter-menu-item">{{ service.title }}</span>
                                    <input
                                        class="filter-checkbox"
                                        type="checkbox"
                                        :value="service.id"
                                        :key="service.id"
                                        v-model="selectedCategories"
                                        v-on:change="updateFilterParams($event);"
                                    />
                                    <div class="control__indicator"></div>
                                </label>
                            </li>
                        </ul>

                        <h4>Region</h4>
                        <ul>
                            <li v-for="(region, index) in regions">
                                <label class="control control--checkbox">

                                    <span class="filter-menu-item">{{ region.node.title }}</span>

                                    <input class="filter-checkbox"
                                           type="checkbox"
                                           :value="getListOfIds(region.relatedEntries.entries)"
                                           :key="region.node.id"
                                           v-model="selectedRegions"
                                           v-on:change="updateFilterParams($event)"
                                    />
                                    <div class="control__indicator"></div>
                                </label>
                            </li>
                        </ul>

                        <div class="clear-filter"><a v-on:click="clearFilters($event)" class="outline-button button">Clear Filters</a></div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
    // Import local storage functions
    import {
        setCheckboxesFromLocalStorage,
        updateLocalStorage,
        clearLocalStorage
    } from '../LocalStorage.js';

    import {
        getRegionCategories,
        getExpertise,
        getServices
    } from './../Queries.js';

    const apiUrl = window.location.href.split('/')[1] + "/api-dep";
    const apiToken = 'zTS0eWOCpo4-QKUfAQ9PGudQe20RWKqt7N0RLb40VN14KSXKLLLDoJ6YM1y-ojEJ';

    export default {
        data() {
            return {
                selectedRegions: [],
                selectedCategories: [],
                selectedRoles: [],
                filterParams: [],

                expertise: [],
                services: [],
                regions: [],

                orderBy: 'date',
                orderAsc: false
            }
        },

        beforeMount: function() {
            this.checkLocalStorage();
            this.getExpertise();
            this.getServices();
            this.getRegions();
        },
        methods: {
            getListOfIds: function(arrayObjects) {
                return Array.prototype.map.call(arrayObjects, function(item) { return item.id; }).join(",")
            },

            updateFilterParams: function () {
                let selectedRegions = null;

                const categories = this.selectedCategories.map(function (category) {
                    return category ? '{element:' + category + '}' : null;
                });

                if ( this.selectedRegions.length ) {
                    selectedRegions = `{element: [${this.selectedRegions}]}`;
                }

                this.filterParams = [
                    ...categories,
                    selectedRegions
                ];

                updateLocalStorage(this.selectedRegions, this.selectedCategories);
                this.$emit("updateFilterParams", this.filterParams);
            },

            clearFilters: function () {
                $('.filter-checkbox').prop('checked', false);
                $('.filter-menu-item').removeClass('grey-active');
                $('html, body').animate({scrollTop: 0}, 500);

                clearLocalStorage([
                    'categoryCriteria',
                    'regionCriteria',
                    'projectOrderUrls'
                ]);

                this.selectedCategories = [];
                this.selectedRegions = [];

                this.$emit("clearFilterParams");
            },

            checkLocalStorage: function () {
                const criteria = setCheckboxesFromLocalStorage();
                const categoryCriteria = criteria.categoryCriteria;
                const regionCriteria = criteria.regionCriteria;

                if (categoryCriteria.length) {
                    this.selectedCategories = categoryCriteria;
                }

                if (regionCriteria.length) {
                    this.selectedRegions = criteria.regionCriteria;
                }

                this.updateFilterParams();
            },

            getExpertise: function () {
                const data = {
                    query: getExpertise()
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api-dep',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.expertise = response.data.categories;
                });
            },

            getServices: function () {
                const data = {
                    query: getServices()
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api-dep',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.services = response.data.categories;

                });
            },

            getRegions: function () {
                const data = {
                    query: getRegionCategories()
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api-dep',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.regions = response.data.categoriesConnection.edges;

                });
            },

        }

    };
</script>
