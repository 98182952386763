<template>
    <span>
        <transition-group name="fade" mode="in-out">
            <team-item
                v-for="(teamMember, index) in teamMembers"
                :title="teamMember.title"
                :url="teamMember.url"
                :position="teamMember.position"
                :image="teamMember.headshot[0]"
                :officeLocation="teamMember.officeLocation[0]"
                :key="teamMember.id"
            />
        </transition-group>
    </span>
</template>

<script>
    const apiToken = 'zTS0eWOCpo4-QKUfAQ9PGudQe20RWKqt7N0RLb40VN14KSXKLLLDoJ6YM1y-ojEJ';
    // Import components
    import TeamItem from './TeamItem';

    // Import queries
    import {
        getCategoryTeam,
    } from '../Queries.js';

    export default {
        components: {
            TeamItem,
        },

        props: {
            categoryId: Number
        },

        mounted: function() {
            this.getTeamMembers();
        },

        data() {
            return {
                teamMembers: [],
                expertiseCategory: '{element: ' + this.categoryId + '}',
                offset: 0,
                limit: 0,
                currentPage: 0,
                totalPages: 0,

            }
        },

        methods: {
            getTeamMembers: function () {
                const data = {
                    query: getCategoryTeam(
                        this.categoryId,
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api-dep',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.teamMembers = response.data.categories[0].team;

                });
            },

        },


    };
</script>
<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
