<template>
<div>

    <div data-aos="fade" class="filter-toggle-wrapper aos-item aos-init aos-animate" style="position: relative;"><!---->
        <p class="filter-toggle">Filters <span></span></p>
        <div class="clear"></div>
    </div>

    <div class="filter-menu" id="filter-menu">
        <div>
            <div id="sticky-anchor">
                <div class="filter-menu-inner" style="opacity: 1;">

                    <h4>Leadership</h4>
                    <ul>
                        <li v-for="(role, index) in roles">
                            <label class="control control--checkbox">
                                <span class="filter-menu-item">{{ role.title }}</span>
                                <input class="filter-checkbox"
                                       type="checkbox"
                                       :value="role.id"
                                       :key="role.id"
                                       v-model="selectedRoles"
                                       v-on:change="updateTeamFilterParams($event)"
                                />
                                <div class="control__indicator"></div>
                            </label>
                        </li>
                    </ul>

                    <h4>Services</h4>
                    <ul>
                        <li v-for="(service, index) in services">
                            <label class="control control--checkbox">
                                <span class="filter-menu-item">{{ service.title }}</span>
                                <input class="filter-checkbox"
                                       type="checkbox"
                                       :value="service.id"
                                       :key="service.id"
                                       v-model="selectedCategories"
                                       v-on:change="updateTeamFilterParams($event)"
                                />
                                <div class="control__indicator"></div>
                            </label>
                        </li>
                    </ul>

                    <h4>Studio</h4>
                    <ul>
                        <li v-for="(region, index) in regions">
                            <label class="control control--checkbox">
                                <span class="filter-menu-item">{{ region.title }}</span>
                                <input class="filter-checkbox"
                                       type="checkbox"
                                       :value="region.id"
                                       :key="region.id"
                                       v-model="selectedRegions"
                                       v-on:change="updateTeamFilterParams($event)"
                                />
                                <div class="control__indicator"></div>
                            </label>
                        </li>
                    </ul>

                    <div class="clear-filter"><a v-on:click="clearFilters($event)" class="outline-button button">Clear Filters</a></div>

                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>

    import {
        getRegions,
        getRoles,
        getServices
    } from './../Queries.js';

    const apiUrl = window.location.href.split('/')[1] + "/api";
    const apiToken = 'crg01qh0qBHY-uimtEyalTVakRr22YO2';

    export default {
        data() {
            return {
                // Team states
                selectedRegions: [],
                selectedCategories: [],
                selectedRoles: [],
                teamFilterCriteria: [],
                regionParams: [],

                roles: [],
                services: [],
                regions: [],
            }
        },

        beforeMount: function() {
            this.getRoles();
            this.getServices();
            this.getRegions();
        },

        methods: {
            getRoles: function () {

                const data = {
                    query: getRoles()
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.roles = response.data.categories;
                });

            },

            getServices: function () {

                const data = {
                    query: getServices()
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.services = response.data.categories;
                });

            },

            getRegions: function () {

                const data = {
                    query: getRegions()
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.regions = response.data.entries;
                });
            },

            updateTeamFilterParams: function () {

                const services = this.selectedCategories.length ? this.selectedCategories : null;
                const selectedRoles = this.selectedRoles.length ? this.selectedRoles : null;
                let categoryParams = null;

                if ( services !== null && selectedRoles !== null ) {
                    categoryParams = services.concat(selectedRoles);
                } else if ( services !== null && selectedRoles === null ) {
                    categoryParams = services;
                } else {
                    categoryParams = selectedRoles;
                }

                this.filterParams = categoryParams ? '[' + categoryParams + ']' : null;
                this.regionParams = this.selectedRegions.length ? '[' + this.selectedRegions + ']' : null;

                this.$parent.offset = 0;
                this.$parent.loadMore = true;
                this.$emit("updateFilterParams", this.filterParams , this.regionParams);
            },

            clearFilters: function () {
                $('.filter-checkbox').prop('checked', false);
                $('.filter-menu-item').removeClass('grey-active');
                $('html, body').animate({scrollTop: 1000}, 500);

                this.selectedCategories = [];
                this.selectedRegions = [];
                this.selectedRoles = [];

                this.$emit("clearFilterParams");
            },

        }

    };
</script>
