<template>
    <div>

        <nav class="articles-nav expertise-grid-nav">
            <ul>
                <li id="nav-item-0" class="insights-tab projects-menu-link articles-nav-item">Projects</li>
                <li id="nav-item-1" class="insights-tab insights-menu-link articles-nav-item">Insights</li>
                <li id="nav-item-2" class="insights-tab team-menu-link articles-nav-item">Team</li>
                <div class="underbar"></div>
            </ul>

            <p class="grid-sort-toggle date-toggle">
                Sort By<span v-on:click="orderEntriesBy('date', 'expertise')">date</span>
                <span v-on:click="orderEntriesBy('title', 'expertise')">A-Z</span></p>
            <p class="grid-sort-toggle insights-toggle">Sort By Type<span></span></p>
        </nav>

        <div id="projectGrid" class="articles-grid">
            <div class="articles-grid-inner" style="display: inline-block;">

                <!-- PROJECT GRID ======================================================================================= -->
                <div id="grid-0" data-title="Projects" class="articles-grid-projects articles-grid-group">
                    <expertise-project-grid
                        :category-id="categoryId"
                        ref="projectGrid"
                    ></expertise-project-grid>
                </div>
                <!-- END PROJECT GRID ======================================================================================= -->

                <!-- INSIGHTS GRID ======================================================================================= -->
                <div id="grid-1" data-title="Insights" class="articles-grid-insights Insights articles-grid-group">
                    <expertise-insight-grid
                        :category-id="categoryId"
                    ></expertise-insight-grid>
                </div>
                <!-- END INSIGHTS GRID ======================================================================================= -->

                <!-- TEAM GRID ======================================================================================= -->
                <div id="grid-2" data-title="Team" class="articles-grid-team articles-grid-group insights-team-grid">
                    <expertise-team-grid
                        :category-id="categoryId"
                    ></expertise-team-grid>
                </div>
                <!-- END TEAM GRID ======================================================================================= -->

            </div>
        </div>
    </div>
</template>


<script>
    const apiToken = 'crg01qh0qBHY-uimtEyalTVakRr22YO2';
    // Import components
    import ExpertiseProjectGrid from './ExpertiseProjectGrid';
    import ExpertiseInsightGrid from './ExpertiseInsightGrid';
    import ExpertiseTeamGrid from './ExpertiseTeamGrid';

    export default {
        components: {
            ExpertiseProjectGrid,
            ExpertiseInsightGrid,
            ExpertiseTeamGrid,

        },

        props: {
            categoryId: Number,
            orderBy: 'date',
            orderAsc: false
        },

        methods: {
            orderEntriesBy: function(orderBy) {
                this.$refs.projectGrid.orderEntriesBy(orderBy)
            }
        }
    };
</script>
