<template>
    <div class="grid-one-column grid-item">
        <a :href="url">
            <div class="project-grid-text-wrapper">
                <h3 class="project-title">{{ title }}</h3>
                <p class="project-location">{{ location }} </p>
            </div>
            <div class="gradient-overlay"></div>
            <div class="thumb">
                <picture>
                    <source :data-srcset="image.optimizedGridImage.srcsetWebP"
                            type="image/webp"
                    />
                    <img :src="image.optimizedGridImage.placeholderBox"
                         :data-src="image.optimizedGridImage.src"
                         :data-srcset="image.optimizedGridImage.srcset"
                         sizes="415px"
                         class="zoom lazyload"
                    />
                </picture>
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            url: String,
            title: String,
            location: String,
            image: Object,
        },

    };
</script>
