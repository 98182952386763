<template>
    <div>
        <team-filter v-on:updateFilterParams="updateFilterParams" v-on:clearFilterParams="clearFilterParams" />

        <section id="projectGrid" class="project-grid full-width aos-item listingPage-team" data-aos="fade" style="display: inline-block; min-height: 600px; overflow: hidden;">
            <p v-if="!teamMembers.length" class="no-results aos-item" data-aos="fade">No results found.</p>

            <transition-group name="fade" mode="in-out">
                <team-item
                    v-for="(teamMember, index) in teamMembers"
                    :title="teamMember.title"
                    :url="teamMember.url"
                    :position="teamMember.position"
                    :image="teamMember.headshot[0]"
                    :officeLocation="teamMember.officeLocation[0]"
                    :key="teamMember.id"
                    :id="'TM_'+teamMember.id"
                />
            </transition-group>
        </section>

        <div style="display: inline-block; width: 100%;">
            <scroll-loader :loader-method="getMoreTeamMembers" :loader-enable="loadMore" v-if="loadMore === true">
                <div aria-label="Loading more projects" class="loading-icon">
                    <div class="spinner spinner--project">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>
                <div class="clear"></div>
            </scroll-loader>
        </div>

        <div class="clear" style="height: 68px;"></div>

    </div>
</template>

<script>
    import Vue from 'vue'
    // Import components
    import ScrollLoader from 'vue-scroll-loader'
    import TeamFilter from './TeamFilter';
    import TeamItem from './TeamItem';

    import {
        getTeamMembers,
        getRegions,
        getRoles,
        getServices
    } from './../Queries.js';

    Vue.use(ScrollLoader);

    const apiUrl = window.location.href.split('/')[1] + "/api";
    const apiToken = 'crg01qh0qBHY-uimtEyalTVakRr22YO2';

    export default {
        components: {
            TeamFilter,
            TeamItem
        },

        beforeMount: function() {
            this.getTeamMembers();
        },
        data() {
            return {
                teamMembers: [],
                filterParams: null,
                regionParams: null,
                offset: 0,
                limit: 12,
                teamLimit: 16,
                totalCount: 0,
                currentPage: 0,
                loadMore: false,
                updateFilter: false,

            }
        },

        methods: {

            updateFilterParams(value, value2) {
                this.filterParams = value;
                this.regionParams = value2;
                this.offset = 0;
                this.updateFilter = true;
                this.loadMore = true;

                this.getTeamMembers();
            },

            updateRegionParams() {
                this.filterParams = null;
                this.offset = 0;
                this.updateFilter = true;
                this.loadMore = true;

                this.getTeamMembers();
            },

            clearFilterParams() {
                this.offset = 0;
                this.filterParams = null;
                this.regionParams = null;
                this.loadMore = true;
                this.updateFilter = true;
                this.currentPage = true;
                this.totalPages = true;

                this.getTeamMembers();
            },

            getTeamMembers: function () {

                const data = {
                    query: getTeamMembers(
                        this.filterParams,
                        this.regionParams,
                        this.teamLimit,
                        this.offset
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    // enable load more once entries are loaded to the page
                    this.loadMore = true;

                    const entries = response.data.entries;

                    this.currentPage = 1;
                    this.totalPages = Math.ceil(response.data.entryCount/this.teamLimit);
                    this.teamMembers = entries;

                    if ( this.updateFilter === true ) {
                        this.updateFilter = false;
                    }

                    if ( this.currentPage === this.totalPages ) {
                        this.loadMore = false;
                    }

                    this.offset = this.offset + this.teamLimit;

                });

            },

            getMoreTeamMembers: function () {
                const data = {
                    query: getTeamMembers(
                        this.filterParams,
                        this.regionParams,
                        this.teamLimit,
                        this.offset
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {

                    const entries = response.data.entries;

                    this.currentPage = this.currentPage + 1;
                    this.teamMembers = this.teamMembers.concat(entries);
                    this.offset = this.offset + this.teamLimit;

                    if ( this.currentPage === this.totalPages ) {
                        this.loadMore = false;
                    }

                });

            },

            getRoles: function () {

                const data = {
                    query: getRoles()
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.roles = response.data.categories;
                });

            },

            getServices: function () {

                const data = {
                    query: getServices()
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.services = response.data.categories;

                });

            },

            getRegions: function () {

                const data = {
                    query: getRegions()
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    this.regions = response.data.entries;

                });
            },

        },
        updated: function () {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been re-rendered
                $('.no-results').css('display', 'block');
            });
        }

    };
</script>
<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
