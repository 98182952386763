<template>
    <div>
        <span class="expertise-grid-inner">
            <transition-group>
                <project-expertise-item
                    v-for="(project, index) in projects"
                    :key="project.id"
                    :url="project.url"
                    :title="project.title"
                    :image="project.slideshow[0].image[0]"
                />
            </transition-group>
        </span>
        <div v-if="currentCount < totalCount" style="width: 100%;">
            <a aria-label="Load more projects"
               v-on:click="getMoreProjects()"
               id="loadMore-0"
               class="load-more button red-outline-button"
            >
                <span>Load More</span>
                <div class="spinner spinner--insights">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    const apiToken = 'zTS0eWOCpo4-QKUfAQ9PGudQe20RWKqt7N0RLb40VN14KSXKLLLDoJ6YM1y-ojEJ';
    // Import components
    import ProjectExpertiseItem from './ProjectExpertiseItem';

    // Import queries
    import {
        getCategoryProjects,
        getProjectsLength
    } from '../Queries.js';

    export default {
        components: {
            ProjectExpertiseItem,
        },

        props: {
            categoryId: Number
        },

        beforeMount: function() {
            this.getTotalCount();
        },

        mounted: function() {
            this.getProjects();
        },

        data() {
            return {
                projects: [],
                offset: 0,
                limit: 8,
                totalCount: 0,
                currentCount: 0,
                orderAsc: false

            }
        },

        methods: {
            getTotalCount: function () {
                const data = {
                    query: getProjectsLength (
                        this.categoryId,
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api-dep',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    // console.log(response.data.categories);
                    this.totalCount = response.data.categories[0].projects.length;
                    // this.totalCount = response.data.categories[0].projects.length;
                });
            },

            getProjects: function () {
                const data = {
                    query: getCategoryProjects(
                        this.categoryId,
                        this.limit,
                        this.offset
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api-dep',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                };

                $.ajax(apiCallSettings).done(response => {
                    const entries = response.data.categories[0].projects;

                    this.projects = entries;
                    this.currentCount = entries.length;
                    this.offset = this.offset + this.limit;
                });
            },

            getMoreProjects: function () {
                const data = {
                    query: getCategoryProjects(
                        this.categoryId,
                        this.limit,
                        this.offset
                    )
                };

                const apiCallSettings = {
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    type: 'POST',
                    url: '/api-dep',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    },
                    beforeSend: () => {
                        $('#loadMore-0').addClass('loading-more');
                    }
                };

                $.ajax(apiCallSettings).done(response => {
                    const entries = response.data.categories[0].projects;

                    this.projects = this.projects.concat(entries);

                    $('#loadMore-0').removeClass('loading-more');

                    this.offset = this.offset + this.limit;
                    this.currentCount = this.currentCount + entries.length;
                });
            },


            orderEntriesBy: function (orderBy) {
                this.orderAsc = this.orderAsc === false;

                if (orderBy === 'title') {
                    this.sortByName(this.orderAsc);
                } else {
                    this.sortByDate(this.orderAsc);
                }

            },

            sortByName: function (orderAsc) {
                const sorted = this.projects.sort((a, b) => {
                    const titleA = a.title.toUpperCase();
                    const titleB = b.title.toUpperCase();

                    if (orderAsc === true) {

                        if (titleA < titleB) {
                            return -1;
                        }

                        if (titleA > titleB) {
                            return 1;
                        }

                    } else {
                        if (titleB < titleA) {
                            return -1;
                        }

                        if (titleB > titleA) {
                            return 1;
                        }

                    }
                    return 0;

                });

                this.projects = [
                    ...sorted
                ];

            },

            sortByDate: function (orderAsc) {
                console.log('here');
                const sorted = this.projects.sort((a, b) => {
                    if (orderAsc === true) {
                        return a.postDate - b.postDate;
                    } else {
                        return b.postDate - a.postDate;
                    }

                });

                this.projects = [
                    ...sorted
                ];
            },


        },


    };
</script>
<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
