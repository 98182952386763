export function setCheckboxesFromLocalStorage() {
  // Sets our variable to local storage value or falls back to empty array
  // const playlistCriteria = localStorage.playlistCriteria ? localStorage.playlistCriteria.split(',') : [];
  const categoryCriteria = localStorage.categoryCriteria ? localStorage.categoryCriteria.split(',') : [];
  const regionCriteria = localStorage.regionCriteria ? JSON.parse(localStorage.regionCriteria) : [];

  return { categoryCriteria, regionCriteria };
}

export function updateLocalStorage(regions, categories) {
  localStorage.regionCriteria = JSON.stringify(regions);
  localStorage.categoryCriteria = categories;
}

export function clearLocalStorage(localStorageArray) {
  localStorageArray.map(function(item) {
      localStorage.removeItem(item);
  });
}

export function setProjectParamsFromLocalStorage() {
  // Sets our variable to local storage value or falls back to empty array
  // let storedPlaylistCriteria = localStorage.playlistCriteria ? localStorage.playlistCriteria.split(',') : [];
  let storedCategoryCriteria = localStorage.categoryCriteria ? localStorage.categoryCriteria.split(',') : [];
  let storedRegionCriteria = localStorage.regionCriteria ? JSON.parse(localStorage.regionCriteria) : [];

  let prepareRegionCriteriaFormat = storedRegionCriteria.length ? storedRegionCriteria.join(',') : [];
  prepareRegionCriteriaFormat = prepareRegionCriteriaFormat.length ? prepareRegionCriteriaFormat.split(',') : [];

  // Maps our stored category data to the format we need for project parameters
  let categoryParam = storedCategoryCriteria.map(function(category) {
      return '{element:' + category + '}';
  });

  let regionParam = prepareRegionCriteriaFormat.map(function(region) {
      return '{element:' + region + '}';
  });

  if (regionParam.length && categoryParam.length) {
      let regions = `{element: [${regionParam}]}`;

      categoryParam = [
          ...categoryParam,
          regions ? regions : null
      ];
  } else if (regionParam.length) {
      categoryParam = `{element: [${regionParam}]}`;
  }


}
