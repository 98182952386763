// Import libraries
import Vue from 'vue';
import $ from 'jquery';
import TeamGrid from './components/TeamGrid';
import ProjectGrid from './components/ProjectGrid';
import ExpertiseTabs from './components/ExpertiseTabs';

// Import all queries
import {
    getPlaylists,
    getCatalysts,
    getVideos,
    getNews,
    getReleases,
    getResearch,
} from './Queries.js';

(function() {
    const apiToken = 'crg01qh0qBHY-uimtEyalTVakRr22YO2';

    const vm = new Vue({
        el: '#newApp',
        delimiters: ['${', '}'],
        components: {
            TeamGrid,
            ProjectGrid,
            ExpertiseTabs
        },
        props: {}
    });

    if ( document.getElementById('app') ) {
        const vm = new Vue({
            el: '#app',
            delimiters: ['${', '}'],
            components: {
                TeamGrid
            },
            data: {
                // Global states
                totalInsightsCount: 0,

                // Insights states
                selectedCategory: [],
                entries: [],
                limit: 6,
                offset: 0,
                loadMore: false,
                categoryCriteria: [],
                playlistCategoryCriteria: null,

                // Playlist states
                playlistsOrderBy: "postDate desc",
                playlists: [],
                selectedCategories: [],

                orderAsc: false,
                apiUrl: window.location.href.split('/')[1] + "/api",
            },
            beforeMount: function () {
                this.getPlaylists();

                if ($('#listingContainer').length) {
                    this.getInsights();
                }
            },

            mounted: function () {},

            methods: {
                getPlaylists: function () {
                    const data = {
                        query: getPlaylists(this.playlistCategoryCriteria, this.orderBy)
                    };

                    const apiCallSettings = {
                        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                        type: 'POST',
                        url: this.apiUrl,
                        data,
                        headers: {
                            'Authorization': 'Bearer ' + apiToken
                        }
                    };

                    $.ajax(apiCallSettings).done(response => {
                        this.playlists = response.data.entries;

                    });
                },
                /*
               * Projects end
               *
               */


                /*
                * Global start
                *
                */

                orderEntriesBy: function (orderByParam, section) {
                    this.orderBy = orderByParam;
                    this.orderAsc = this.orderAsc === false ? true : false;

                    let state;

                    switch (section) {
                        case 'playlists':
                            state = this.playlists;
                            break;
                        default:
                            state = this.projects;
                            break;
                    }

                    switch (orderByParam) {
                        case 'title':
                            this.sortByName(this.orderAsc, state);
                            break;
                        default:
                            this.sortByDate(this.orderAsc, state);
                            break;
                    }

                },

                sortByName: function (orderAsc, state) {
                    const sorted = state.sort((a, b) => {
                        const titleA = a.title.toUpperCase();
                        const titleB = b.title.toUpperCase();

                        if (orderAsc === true) {

                            if (titleA < titleB) {
                                return -1;
                            }

                            if (titleA > titleB) {
                                return 1;
                            }

                        } else {
                            if (titleB < titleA) {
                                return -1;
                            }

                            if (titleB > titleA) {
                                return 1;
                            }

                        }
                        return 0;

                    });

                    state = [
                        ...sorted
                    ];

                },

                sortByDate: function (orderAsc, state) {
                    let sorted = state.sort((a, b) => {
                        if (orderAsc === true) {
                            return Date.parse(a.postDate) - Date.parse(b.postDate);
                        } else {
                            return Date.parse(b.postDate) - Date.parse(a.postDate);
                        }

                    });

                    state = [
                        ...sorted
                    ];
                },

                updateButtonStatus: function ($element, offset, totalCount) {
                    if (totalCount > offset) {
                        $element.css('display', 'inline-block');
                    } else {
                        $element.css('display', 'none');
                    }
                },
                /*
               * Global end
               */


                /*
                * Insights start
                *
                */
                getInsights: function () {

                    let entrySection = $('#app').attr('class');
                    let data;

                    switch (entrySection) {
                        case 'catalystSection':
                            data = {
                                query: getCatalysts(this.categoryCriteria, this.limit, this.offset)
                            };
                            break;

                        case 'releasesSection':
                            data = {
                                query: getReleases(this.categoryCriteria, this.limit, this.offset)
                            };
                            break;

                        case 'newsSection':
                            data = {
                                query: getNews(this.categoryCriteria, this.limit, this.offset)
                            };
                            break;

                        case 'videosSection':
                            data = {
                                query: getVideos(this.categoryCriteria, this.limit, this.offset)
                            };
                            break;

                        case 'researchSection':
                            data = {
                                query: getResearch(this.categoryCriteria, this.limit, this.offset)
                            };
                            break;

                        default:
                    }

                    const apiCallSettings = {
                        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                        type: 'POST',
                        url: this.apiUrl,
                        data,
                        headers: {
                            'Authorization': 'Bearer ' + apiToken
                        },
                        beforeSend: () => {
                            $('#loadMore').addClass('loading-more');
                        }
                    };

                    $.ajax(apiCallSettings).done(response => {
                        this.totalInsightsCount = response.data.entryCount;
                        const $loadMoreBtn = $('#loadMore');
                        const entries = response.data.entries;
                        $loadMoreBtn.removeClass('loading-more');

                        if (this.loadMore === false) {
                            this.entries = entries;

                        } else {
                            this.entries = this.entries.concat(entries);
                            this.loadMore = false;
                        }

                        this.updateButtonStatus($loadMoreBtn, this.offset, this.totalInsightsCount);

                    });
                    this.offset = this.offset + 6;
                },

                updateProjectFilterParams: function () {
                    let mapCategories = this.selectedCategories.map(function (category) {
                        return category ? category : null;
                    });

                    this.playlistCategoryCriteria = '[' +  mapCategories + ']';
                    this.getPlaylists();

                },

                clearProjectFilters: function () {
                    $('.filter-checkbox').prop('checked', false);
                    $('.filter-menu-item').removeClass('grey-active');
                    $('html, body').animate({scrollTop: 20}, 500);

                    this.categoryCriteria = [];
                    this.playlistCategoryCriteria = null;

                    this.orderBy = "postDate desc";

                    this.getPlaylists();
                },

                filterInsights: function () {
                    this.offset = 0;
                    this.categoryCriteria = `id: ${this.selectedCategory}`;
                    this.getInsights();
                },

                loadMoreInsights: function () {
                    this.getInsights(this.loadMore = true);
                },

                /*
               * Insights end
               *
               */

            },
        });
    }
}());
