<template>
    <div class="article-team-item article-item team-item" :data-title="position" :id="id" style="display: block;">
        <a :href="url">
            <div class="article-team-item-inner">
                <picture v-if="image">
                    <source :data-srcset="image.optimizedHeadshots.srcsetWebP"
                            type="image/webp"
                    />
                    <img :src="image.optimizedHeadshots.placeholderBox"
                         :data-src="image.optimizedHeadshots.src"
                         :data-srcset="image.optimizedHeadshots.srcset"
                         sizes="415px"
                         class="zoom lazyload"
                    />
                </picture>
            </div>
            <p class="article-description">{{ title }}</p>
            <p class="article-description-second">{{ position }}</p>
            <p v-if="officeLocation"
               class="article-description-third"
            >{{ officeLocation.title }}, {{ officeLocation.stateAbbreviation }}
            </p>
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            position: String,
            title: String,
            url: String,
            image: Object,
            officeLocation: Object,
            id: [String, Number],
        }
    }
</script>
