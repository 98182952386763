<template>
    <div class="article-project-item article-item">
    <a :href="url">
        <div class="article-project-item-inner">
            <div class="thumb-wrapper"
                 v-if="image"
            >
                <picture>
                    <source :data-srcset="image.optimizedGridImage.srcsetWebP"
                            type="image/webp"
                    />
                    <img :src="image.optimizedGridImage.placeholderBox"
                         :data-src="image.optimizedGridImage.src"
                         :data-srcset="image.optimizedGridImage.srcset"
                         class="zoom lazyload"
                         sizes="400px"
                    />
                </picture>
            </div>
        </div>
        <p class="article-description article-item">{{ title }}</p>
    </a>
    </div>
</template>

<script>
    export default {
        props: {
            url: String,
            title: String,
            location: String,
            image: Object,
        },

    };
</script>
