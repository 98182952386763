<template>
    <div class="article-insights-item article-item">

        <a :href="url">
            <div class="article-insights-inner">
                <p class="article-category">{{ typename }}</p>

                <div class="thumb-wrapper" v-if="image">
                    <picture v-if="image">
                        <source :data-srcset="image.optimizedListImage.srcWebp"
                                type="image/webp"
                        />

                        <img :src="image.optimizedListImage.placeholderBox"
                             :data-src="image.optimizedListImage.src"
                             :data-srcset="image.optimizedListImage.srcset"
                             sizes="(min-width: 768px) 300px,
                                (max-width: 768px) 750px"
                             class="zoom lazyload"
                             alt=""
                        />
                    </picture>
                </div>
            </div>

            <div class="article-text">
                <p class="article-title">{{ title }}</p>
                <p class="article-date">{{ postDate }}</p>
                <p class="article-copy"><span v-html="metaDescription"></span></p>
            </div>

            <div class="clear"></div>
        </a>

    </div>
</template>

<script>
    export default {
        props: {
            url: String,
            title: String,
            postDate: String,
            typename: String,
            metaDescription: String,
            image: Object,
            posterImage: Object,
        },

    };
</script>
